import { useState, useEffect, useContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EditorState, Modifier } from 'draft-js';
import { BaseEmoji } from 'emoji-mart';

import {
  COMMON_SOCIAL_PROVIDER_TYPE,
  COMMON_SOCIAL_MEDIA_NAME,
  ADD_POST_CONTENT_MAX_TEXT_LENGTH,
  AP_ADD_TO_POST_TAB_NAMES,
  AP_PREVIEW_TYPES,
  MG_MEDIA_TYPE,
  SIDE_NAV_NAME,
  CREATOR_SOCIAL_MEDIAS,
  IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM,
  CREATOR_TEXT_EDITOR_ALERT_MSG,
  GET_HASHTAG_ONLY_REGEX,
  STRING_WITH_PHONE_NUMBER,
  SOCIAL_MEDIA_POST_TYPE,
  SOCIAL_MEDIA_POST_TYPE_VALUES,
  SettingsHashtagsActionsVal,
  HASHTAG_BLOCK_DEFAULT_SPACING,
  AP_ACTION_TYPES,
  AI_SUBSCRIPTION_PLAN_NAMES,
  ADD_POST_CONTENT_MAX_HASHTAGS_LENGTH
} from 'utils/constants';
import { apAccountContainerTypes, apFranReducerTypes, IStore } from 'types';
import {
  addPostUpdateSavedPostDetails,
  addPostSetLinkData,
  addPostSetEditConfirmForApprovedPost,
  addPostGetFBTagsListRequest,
  addPostGetSelectedFBTagList,
  addPostIsEmojiOpen,
  setCreatorModalName,
  getVideoCompatibilityRequest,
  setCreatorTextAlertMsg,
  mgGetCloudResponseList,
  mgSetAddedAssetsCount,
  setCustomizeLinkEnable,
  addCustomizeLink,
  addPostUpdateCustomTextEditor
} from 'actions';
import { removeTagListBasedOnDataAvail, convertEditorStateToString, handleHashtagsInCreator, getPreviewSocialMediaList, convertStringToEditorState, splitTextAndHashtagsFromText } from 'utils/helpers';
import { CouponOrLinkPreview } from 'widgets/Preview';
import { AddToYourPostSection } from './AddToYourPost';
import { Loading, TranslateTextSec } from 'components';
import { LimitExceedAlertMessage, CreatorInstagramPhotos, CreatorTextCount, CreatorTextEditor, SocialMediaAlertMessage } from '../CommonModal';
import { EditorStateContext } from '..';
import { enableAICaptionValidation, setAIPostContentOpen, setAISubscriptionDetailsModal } from 'actions/modules/ai-content-creator';
import { useNavBarData, useSiteRestriction } from 'utils/hooks';
import { RenderAddContentList } from './RenderAddContentList';
import { LeftCreatorMediaList } from './LeftCreatorMediaList';
import { regexes } from 'widgets/DraftjsHashtagsPlugin/hashtagRegex';
import Picker from 'widgets/ReactEmojiPicker';
import { CustomDropdown } from 'widgets/CustomDropdown';
import { ToggleSwitch } from 'widgets/Switch';

export const AddTextSection = (props: apAccountContainerTypes.IAddPostTextSectionProps) => {
  const { socialMediaList, addContentList } = props;

  const dispatch = useDispatch();
  const { aiRestriction } = useSiteRestriction();
  const { subNavPageName } = useNavBarData();

  const editData = useSelector((state: IStore) => state.calendar.editableData.editData);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const editConfirmObj = useSelector((state: IStore) => state.addPostAccount.editConfirmObj);
  const isPostEditable = useSelector((state: IStore) => state.addPostAccount.editConfirmObj.isPostEditable);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const isLinkFetching = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.isLinkFetching);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const facebookTags = useSelector((state: IStore) => state.addPostAccount.fbTagsData.fbTagsList);
  const isFBPageTaggingEnabled = useSelector((state: IStore) => state.accounts?.accountDetails?.account?.facebook_page_tagging_feature || false);
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);
  const isEmojiOpen = useSelector((state: IStore) => state.addPostFranchisorAction.isEmojiOpen);
  const isAiContentOpen = useSelector((state: IStore) => state.aiContentCreator.isAiContentOpen);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  const isRallioai = useSelector((state: IStore) => state.accountSwitcher?.rallio_ai || false);
  const videoCompatibility = useSelector((state: IStore) => state.addPostFranchisor.videoCompatibility);
  const creatorTextAlertMsg = useSelector((state: IStore) => state.common.creatorTextAlertMsg);
  const { hashtagsList } = useSelector((state: IStore) => state.addPostFranchisorAction.hashtagsListObj);
  const createPostHashtagSuggestions = useSelector((state: IStore) => state.aiContentCreator.createPostHashtagSuggestions);
  const captionState = useSelector((state: IStore) => state.aiContentCreator.caption);
  const isCustomizeLinkAdded = useSelector((state: IStore) => state.addPostFranchisorAction.isCustomizeLinkAdded || false);
  const isAICaptionValidationEnabled = useSelector((state: IStore) => state.aiContentCreator.isAICaptionValidationEnabled || false);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);
  const accSubscriptionDetails = useSelector(
    (state: IStore) => state.accounts.accountDetails?.account?.active_subscription_details?.[0] || state.accounts.accountDetails?.account?.subscription_details?.[0]
  );
  const aiLimitation = useSelector((state: IStore) => state.aiContentCreator.aiLimitation);
  const hashTagsState = useSelector((state: IStore) => state.settingsHashTags.hashTagsState);
  const addPostAction = useSelector((state: IStore) => state.addPostAccount.addPostAction);
  const pinterestBoards = useSelector((state: IStore) => state.addPostFranchisorAction.pinterestBoards);

  const [editableSocialMedia, setEditableSocialMedia] = useState({ name: '', isEdit: false });
  const [textEditorState, setEditorState] = useContext(EditorStateContext);

  const pinterestBoardOptions = [{ id: 1, value: 'default', label: 'My Pins' }, ...pinterestBoards];
  // const hashTagsLimit = savedPostDetails?.[`${COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM}_text`].match(regexes.validHashtag) || [];

  const showTranslateOption = useMemo(() => {
    if (isRallioai && isAISubscribed && !aiRestriction.button) {
      if (!isContentSupplierFranchisor) {
        return true;
      } else if (accSubscriptionDetails?.franchisor_subscription_type?.plan_name === AI_SUBSCRIPTION_PLAN_NAMES.RC_FREE) {
        return aiLimitation && +aiLimitation?.ai_caption_limit > 0 ? true : false;
      }
      return [AI_SUBSCRIPTION_PLAN_NAMES.TIER2POINT5, AI_SUBSCRIPTION_PLAN_NAMES.RC_PREMIUM].includes(accSubscriptionDetails?.franchisor_subscription_type?.plan_name) ? true : false;
    }
    return false;
  }, [isRallioai, isAISubscribed, aiRestriction.button, accSubscriptionDetails?.franchisor_subscription_type?.plan_name, aiLimitation, isContentSupplierFranchisor]);

  const isStoriesAdded =
    (savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && savedPostDetails?.facebook_story) ||
    (savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && savedPostDetails?.instagram_story) ||
    false;

  const socialMediaPostMemo = useMemo(() => {
    const socialMediaPostType =
      savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? SOCIAL_MEDIA_POST_TYPE : SOCIAL_MEDIA_POST_TYPE.filter((type) => type.value !== SOCIAL_MEDIA_POST_TYPE_VALUES.REELS);
    return socialMediaPostType;
  }, [savedPostDetails?.current_tab]);

  useEffect(() => {
    if (savedPostDetails?.current_tab && addContentList.length && !savedPostDetails[`${savedPostDetails?.current_tab}_text`]) {
      setEditableSocialMedia({
        name: savedPostDetails?.current_tab,
        isEdit: false
      });
    }
  }, [addContentList.length, savedPostDetails?.current_tab, savedPostDetails?.[`${savedPostDetails?.current_tab}_text`]]); // eslint-disable-line

  useEffect(() => {
    if (selectedFBTagList.length && savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) {
      dispatch(addPostGetSelectedFBTagList(removeTagListBasedOnDataAvail(selectedFBTagList, savedPostDetails?.facebook_text || '')));
    }
  }, [savedPostDetails?.current_tab, savedPostDetails?.facebook_text, selectedFBTagList.length]); // eslint-disable-line

  // If the post is locked, close the emoji list
  useEffect(() => {
    if (savedPostDetails?.locked && isEmojiOpen) dispatch(addPostIsEmojiOpen(false));
  }, [savedPostDetails?.locked, isEmojiOpen, dispatch]);

  useEffect(() => {
    const hashTagsLimit: string[] = savedPostDetails?.[`${savedPostDetails?.current_tab}_text`].match(GET_HASHTAG_ONLY_REGEX) || [];
    handleHashtagsInCreator(hashtagsList, hashTagsLimit, dispatch, createPostHashtagSuggestions, captionState);
  }, [savedPostDetails?.[`${savedPostDetails?.current_tab}_text`]]); // eslint-disable-line

  // If the post description is more than the length, close the emoji list
  useEffect(() => {
    const isDisableEmoji = savedPostDetails
      ? IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM.includes(savedPostDetails?.current_tab) &&
        savedPostDetails[`${savedPostDetails?.current_tab}_text`]?.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH[savedPostDetails?.current_tab.toUpperCase()] - 3
        ? true
        : false
      : false;
    if (isEmojiOpen && isDisableEmoji) {
      dispatch(addPostIsEmojiOpen(false));
    }
  }, [savedPostDetails, isEmojiOpen, dispatch]);

  // Google phone RAS-4964 validation Prevent users from creating Google posts with phone numbers in the post body
  useEffect(() => {
    if (savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) {
      const regexData = new RegExp(STRING_WITH_PHONE_NUMBER);
      const editorGoogleText = regexData.test(savedPostDetails?.[`${savedPostDetails?.current_tab}_text`]);
      dispatch(
        addPostUpdateSavedPostDetails(
          savedPostDetails
            ? {
                ...savedPostDetails,
                [`use_${savedPostDetails?.current_tab}`]:
                  IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM.includes(savedPostDetails?.current_tab) &&
                  savedPostDetails?.[`${savedPostDetails?.current_tab}_text`].length > ADD_POST_CONTENT_MAX_TEXT_LENGTH[savedPostDetails?.current_tab.toUpperCase()]
                    ? false
                    : savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE &&
                      (editorGoogleText ||
                        savedPostDetails?.[`${savedPostDetails?.current_tab}_text`].includes('[phone_number]') ||
                        savedPostDetails?.[`${savedPostDetails?.current_tab}_text`].includes('[phone]'))
                    ? false
                    : true
              }
            : null
        )
      );
    }
  }, [savedPostDetails?.google_text, dispatch]); // eslint-disable-line

  useEffect(() => {
    if (selectedMediaList.length && selectedMediaList[0].media_type === MG_MEDIA_TYPE.VIDEO) {
      dispatch(getVideoCompatibilityRequest(selectedMediaList[0].cloudinary_id));
    } else if (!selectedMediaList.length) {
      dispatch(
        addPostUpdateSavedPostDetails(
          savedPostDetails
            ? {
                ...savedPostDetails,
                instagram_story: false,
                facebook_story: false,
                facebook_reel: false,
                use_facebook: savedPostDetails?.facebook_story || savedPostDetails?.facebook_reel ? false : isSocialMediaActive(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK),
                use_instagram: false,
                use_twitter: isSocialMediaActive(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER),
                use_linkedin: isSocialMediaActive(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN),
                use_tiktok: false,
                use_pinterest: false,
                use_google: isSocialMediaActive(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE)
                // ...(savedPostDetails?.facebook_story || savedPostDetails?.facebook_reel ? { use_facebook: false } : savedPostDetails?.instagram_story && { use_instagram: false })
              }
            : null
        )
      );
    }
  }, [selectedMediaList, dispatch]); // eslint-disable-line

  useEffect(() => {
    if (videoCompatibility?.warnings[`${savedPostDetails?.current_tab}_reel` as keyof apFranReducerTypes.IVideoCompatibilityWarnings]?.length) {
      dispatch(
        addPostUpdateSavedPostDetails(
          savedPostDetails
            ? {
                ...savedPostDetails,
                ...(savedPostDetails?.[`${[`${savedPostDetails?.current_tab}_reel`]}`] && { [`use_${savedPostDetails?.current_tab}`]: false })
              }
            : null
        )
      );
    }
  }, [videoCompatibility?.warnings[`${savedPostDetails?.current_tab}_reel` as keyof apFranReducerTypes.IVideoCompatibilityWarnings], savedPostDetails?.facebook_reel, dispatch]); // eslint-disable-line

  useEffect(() => {
    if (savedPostDetails?.current_tab && hashTagsState.action_status === SettingsHashtagsActionsVal.IN_POST && hashTagsState.saved_hashtags?.length && addPostAction === AP_ACTION_TYPES.ADD) {
      const socialMediaTextAndHashtags = splitTextAndHashtagsFromText(savedPostDetails?.[`${savedPostDetails?.current_tab}_text`] ?? '');
      let socialMediaText = socialMediaTextAndHashtags.text || '';
      if (socialMediaText) {
        socialMediaText = `${socialMediaText}${[...new Set([...socialMediaTextAndHashtags.hashTag, ...hashTagsState.saved_hashtags])].join(' ')}`;
      } else {
        socialMediaText = `${HASHTAG_BLOCK_DEFAULT_SPACING}${hashTagsState.saved_hashtags?.join(' ')}`;
      }
      handleUpdateSocialMediaText(savedPostDetails?.current_tab, convertStringToEditorState(socialMediaText));
    }
  }, [hashTagsState, dispatch]); // eslint-disable-line

  const handleSocialMediaSelect = (selectedTab: string | null) => {
    if (isEmojiOpen) dispatch(addPostIsEmojiOpen(false));
    if (creatorTextAlertMsg) dispatch(setCreatorTextAlertMsg(null));
    dispatch(addPostUpdateSavedPostDetails(savedPostDetails ? { ...savedPostDetails, current_tab: selectedTab || socialMediaList[0] } : null));
  };

  const handleUpdateSocialMediaText = (socialMediaKey: string, socialMediaValue: EditorState) => {
    // need to validate caption before save
    if (!isAICaptionValidationEnabled) dispatch(enableAICaptionValidation(true));

    dispatch(addPostUpdateCustomTextEditor({ key: socialMediaKey, value: socialMediaValue }));
    setEditorState({ ...textEditorState, [socialMediaKey]: socialMediaValue });
    const socialMediaText = convertEditorStateToString(socialMediaValue) || '';
    const hashTagsLimit: string[] = socialMediaText.match(GET_HASHTAG_ONLY_REGEX) || [];
    const regexData = new RegExp(STRING_WITH_PHONE_NUMBER);
    const editorGoogleText = regexData.test(savedPostDetails?.[`${socialMediaKey}_text`]);
    // old - const socialMediaText = getContentTextWithMaximumLimit(convertEditorStateToString(socialMediaValue) || '', socialMediaKey);
    dispatch(
      addPostUpdateSavedPostDetails(
        savedPostDetails
          ? {
              ...savedPostDetails,
              [`${socialMediaKey}_text`]: socialMediaText,
              [`use_${socialMediaKey}`]:
                savedPostDetails && !socialMediaText && !selectedMediaList.length && !linkData
                  ? false
                  : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && (!selectedMediaList.length || hashTagsLimit.length > ADD_POST_CONTENT_MAX_HASHTAGS_LENGTH.INSTAGRAM)
                  ? false
                  : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST && (!selectedMediaList.length || hashTagsLimit.length > ADD_POST_CONTENT_MAX_HASHTAGS_LENGTH.PINTEREST)
                  ? false
                  : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN && !socialMediaText
                  ? false
                  : IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM.includes(socialMediaKey) && socialMediaText.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH[socialMediaKey.toUpperCase()] // validated char limit for insta, twitter, linkedin, Google
                  ? false
                  : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK && (!selectedMediaList.length || (selectedMediaList.length && selectedMediaList[0]?.media_type !== MG_MEDIA_TYPE.VIDEO))
                  ? false
                  : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE &&
                    (editorGoogleText || savedPostDetails?.[`${socialMediaKey}_text`].includes('[phone_number]') || savedPostDetails?.[`${socialMediaKey}_text`].includes('[phone]'))
                  ? false
                  : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                  ? savedPostDetails.facebook_story
                    ? selectedMediaList.length
                      ? true
                      : false
                    : savedPostDetails?.facebook_reel
                    ? selectedMediaList.length && selectedMediaList?.[0].media_type === MG_MEDIA_TYPE.VIDEO
                      ? true
                      : false
                    : selectedMediaList.length
                    ? true
                    : Boolean(socialMediaText)
                  : socialMediaText
                  ? true
                  : savedPostDetails[`use_${socialMediaKey}`]
            }
          : null
      )
    );
    if (socialMediaText.length && socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && creatorTextAlertMsg) dispatch(setCreatorTextAlertMsg(null));
  };

  const handleUpdateSocialMediaCheckbox = (socialMediaKey: string, socialMediaValue: boolean) => {
    if (isPostEditable) {
      handleEditConfirmation();
    } else {
      dispatch(
        addPostUpdateSavedPostDetails(
          savedPostDetails
            ? {
                ...savedPostDetails,
                [socialMediaKey]: socialMediaValue
              }
            : null
        )
      );
    }
  };

  const handleActiveSocialMedia = (socialMedia: string) => {
    return !savedPostDetails?.[`${socialMedia}_text`]?.length && !selectedMediaList.length ? false : true;
  };

  const isSocialMediaActive = (socialMedia: string) => {
    return !savedPostDetails?.[`${socialMedia}_text`]?.length && !selectedMediaList.length && !linkData ? false : savedPostDetails?.[`use_${socialMedia}`];
  };

  const handleDeletePreview = () => {
    dispatch(addPostSetLinkData(null));
    dispatch(
      addPostUpdateSavedPostDetails(
        savedPostDetails
          ? {
              ...savedPostDetails,
              use_facebook: savedPostDetails?.facebook_story
                ? Boolean(selectedMediaList?.length)
                : savedPostDetails?.facebook_reel
                ? selectedMediaList?.[0]?.media_type === MG_MEDIA_TYPE.VIDEO
                : handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK),
              use_instagram: selectedMediaList.length ? handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) : false,
              use_twitter: handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER),
              use_linkedin: handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN),
              use_tiktok: selectedMediaList?.[0]?.media_type === MG_MEDIA_TYPE.VIDEO,
              use_google: handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE),
              use_pinterest: selectedMediaList.length ? handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST) : false,
              is_thumbnail_show: true,
              link_index: 0,
              facebook_call_to_action_type: null,
              link_preview_customized: false,
              google_call_to_action_type: null,
              google_call_to_action_url: ''
            }
          : null
      )
    );
    dispatch(mgGetCloudResponseList([]));
    dispatch(mgSetAddedAssetsCount(0));
    dispatch(setCustomizeLinkEnable(false));
    if (isCustomizeLinkAdded) {
      dispatch(addCustomizeLink(false));
    }
  };

  const handleEditConfirmation = () => {
    dispatch(addPostSetEditConfirmForApprovedPost({ ...editConfirmObj, isShowModal: true, isEditConfirmed: false }));
  };

  const handleTagSearch = (searchValue: string) => {
    dispatch(addPostGetFBTagsListRequest(searchValue));
  };

  const handleUpdateSettingsCheckbox = (socialMediaKey: string, socialMediaValue: boolean, socialMedia: string) => {
    const unusedPostType: Record<string, boolean> = {};
    [SOCIAL_MEDIA_POST_TYPE_VALUES.REELS, SOCIAL_MEDIA_POST_TYPE_VALUES.STORY]
      .filter((option) => `${socialMedia}_${option}` !== socialMediaKey)
      .forEach((key) => {
        unusedPostType[`${socialMedia}_${key}`] = false;
      });

    if (isPostEditable) {
      handleEditConfirmation();
    } else {
      dispatch(
        addPostUpdateSavedPostDetails(
          savedPostDetails
            ? {
                ...savedPostDetails,
                ...(socialMediaKey !== `${socialMedia}_${SOCIAL_MEDIA_POST_TYPE_VALUES.POST}` && { [socialMediaKey]: socialMediaValue }),
                [`use_${socialMedia}`]:
                  socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                    ? socialMediaKey === `${socialMedia}_${SOCIAL_MEDIA_POST_TYPE_VALUES.STORY}`
                      ? Boolean(selectedMediaList?.length && !linkData)
                      : socialMediaKey === `${socialMedia}_${SOCIAL_MEDIA_POST_TYPE_VALUES.REELS}`
                      ? Boolean(selectedMediaList?.[0]?.media_type === MG_MEDIA_TYPE.VIDEO && !linkData)
                      : Boolean(savedPostDetails?.[`${socialMedia}_text`] || selectedMediaList.length || linkData)
                    : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                    ? Boolean(selectedMediaList.length)
                    : Boolean(savedPostDetails?.[`${socialMedia}_text`] || selectedMediaList.length || linkData),
                ...unusedPostType
              }
            : null
        )
      );
    }
  };

  const setModalName = (data: string | null) => dispatch(setCreatorModalName(data));

  const handleAiClick = () => {
    if (!isAISubscribed) {
      dispatch(setAISubscriptionDetailsModal({ type: 'trialPeriod', isOpen: true }));
    } else {
      dispatch(setAIPostContentOpen(!isAiContentOpen));
    }
    // navigate(ROUTES.CONTENT_CREATOR.replace(':type', userOwnership).replace(':id', id.toString()).replace('*', COMMON_PAGE_TYPE.NEW));
    // if (!isAiContentOpen)
  };

  const renderSocialMediaTabDetails = (socialMedia: string, socialMediaLabel: string) => {
    const hashTagsLimit = savedPostDetails?.[`${socialMedia}_text`]?.match(regexes.validHashtag) || [];
    const regexData = new RegExp(STRING_WITH_PHONE_NUMBER);
    const editorGoogleText = regexData.test(savedPostDetails?.[`${socialMedia}_text`]);
    const isPhoneNumber =
      COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE === savedPostDetails?.current_tab &&
      (editorGoogleText || savedPostDetails?.[`${socialMedia}_text`].includes('[phone_number]') || savedPostDetails?.[`${socialMedia}_text`].includes('[phone]'));

    return (
      <div className="tw-content flexiAlign cr-prt-wrap">
        <div className="pm-options">
          <div className={`form-check${savedPostDetails?.locked ? ` pointer-events-none` : ``}`}>
            <p className="label-txt">
              Publish <span className="mandatory cursor-help">*</span>
            </p>
            <ToggleSwitch
              id={'material-switch-21'}
              handleOnChange={() => handleUpdateSocialMediaCheckbox(`use_${socialMedia}`, !savedPostDetails?.[`use_${socialMedia}`])}
              checkedStatus={savedPostDetails ? (CREATOR_SOCIAL_MEDIAS.includes(socialMedia) ? savedPostDetails[`use_${socialMedia}`] : savedPostDetails[`use_google`]) : false}
              disabled={
                savedPostDetails?.locked
                  ? true
                  : COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM === socialMedia
                  ? !selectedMediaList?.length
                  : COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK === socialMedia
                  ? savedPostDetails?.facebook_story || savedPostDetails?.facebook_reel
                    ? !selectedMediaList.length
                      ? true
                      : false
                    : selectedMediaList.length || savedPostDetails?.[`${socialMedia}_text`] || linkData
                    ? false
                    : true
                  : COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK === socialMedia
                  ? selectedMediaList?.[0]?.media_type !== MG_MEDIA_TYPE.VIDEO
                  : COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST === socialMedia
                  ? !selectedMediaList?.length
                  : !(savedPostDetails?.[`${socialMedia}_text`] || selectedMediaList.length || linkData)
              }
            />
          </div>
          {[COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM].includes(socialMedia) ? (
            <div className={`prsMain${savedPostDetails?.locked ? ' pointer-events-none' : ''}`}>
              {/* <div className="label-txt">
                Publish as <span>*</span>
              </div> */}
              <CustomDropdown
                id="post-type-dropdown"
                classNamePrefix={'prsData'}
                // menuIsOpen={true}
                placeholder={'Select post type'}
                options={socialMediaPostMemo}
                value={socialMediaPostMemo.find((datum) => savedPostDetails?.[`${socialMedia}_${datum.value}`] === true) || SOCIAL_MEDIA_POST_TYPE[0]}
                onChange={(eventObj: any) => handleUpdateSettingsCheckbox(`${socialMedia}_${eventObj?.value}`, true, socialMedia)}
              />
            </div>
          ) : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST && pinterestBoards.length ? (
            <div className={`prsMain${savedPostDetails?.locked ? ' pointer-events-none' : ''}`}>
              {/* <div className="label-txt">
                Publish as <span>*</span>
              </div> */}
              <CustomDropdown
                id="post-type-dropdown"
                classNamePrefix={'prsData'}
                // menuIsOpen={true}
                placeholder={'Select board'}
                options={pinterestBoardOptions}
                value={pinterestBoards.find((board) => board.value === savedPostDetails?.pinterest_board_name) || pinterestBoardOptions[0]}
                onChange={(eventObj: any) =>
                  dispatch(addPostUpdateSavedPostDetails(savedPostDetails ? { ...savedPostDetails, pinterest_board_name: eventObj.value === 'default' ? null : eventObj.value } : null))
                }
              />
            </div>
          ) : null}
          {showTranslateOption && (
            <div className={`translateMain ${savedPostDetails && (!getPreviewSocialMediaList(savedPostDetails).length || savedPostDetails?.locked) ? 'pointer-events-none' : ''}`}>
              <TranslateTextSec />
            </div>
          )}
        </div>
        {creatorTextAlertMsg ? <SocialMediaAlertMessage message={creatorTextAlertMsg} /> : null}
        {!creatorTextAlertMsg && COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK === savedPostDetails?.current_tab && (savedPostDetails?.facebook_story || savedPostDetails?.facebook_reel) ? (
          !selectedMediaList?.length ? (
            <SocialMediaAlertMessage
              message={
                linkData
                  ? CREATOR_TEXT_EDITOR_ALERT_MSG.LINK_ALERT_MSG_WITH_STORY_OR_REEL.replaceAll(`/post-type/`, 'story or reel')
                  : savedPostDetails?.facebook_reel
                  ? CREATOR_TEXT_EDITOR_ALERT_MSG.FACEBOOK_REEL
                  : CREATOR_TEXT_EDITOR_ALERT_MSG.FACEBOOK_STORY
              }
            />
          ) : savedPostDetails?.facebook_reel && selectedMediaList?.[0]?.media_type !== MG_MEDIA_TYPE.VIDEO ? (
            <SocialMediaAlertMessage message={CREATOR_TEXT_EDITOR_ALERT_MSG.FACEBOOK_REEL} />
          ) : null
        ) : null}
        {COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK === savedPostDetails?.current_tab &&
        savedPostDetails?.[`${socialMedia}_text`] &&
        (!selectedMediaList?.length || selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.PHOTO) ? (
          <SocialMediaAlertMessage message={CREATOR_TEXT_EDITOR_ALERT_MSG.TIKTOK} />
        ) : null}
        {/* reel video compatibility warning for fb and instagram */}
        {savedPostDetails?.[`${socialMedia}_reel`] && videoCompatibility?.warnings[`${socialMedia}_reel` as keyof apFranReducerTypes.IVideoCompatibilityWarnings]?.length ? (
          <SocialMediaAlertMessage message={videoCompatibility?.warnings[`${socialMedia}_reel` as keyof apFranReducerTypes.IVideoCompatibilityWarnings]?.[0]} />
        ) : null}
        {/* When context, media and link are not added, when instagram has no media or contain coupon/link,
            when linkedin or google has video media, the status checkbox should not be shown */}
        {
          (savedPostDetails?.instagram_story || savedPostDetails?.[`${socialMedia}_text`]) && socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM ? (
            <>
              {!selectedMediaList.length ? <SocialMediaAlertMessage message={CREATOR_TEXT_EDITOR_ALERT_MSG.INSTAGRAM} /> : null}
              {hashTagsLimit.length > ADD_POST_CONTENT_MAX_HASHTAGS_LENGTH.INSTAGRAM ? <LimitExceedAlertMessage socialMedia={socialMedia} isHashTagLimit /> : null}
              {savedPostDetails?.[`${socialMedia}_text`].length > ADD_POST_CONTENT_MAX_TEXT_LENGTH.INSTAGRAM ? <LimitExceedAlertMessage socialMedia={socialMedia} /> : null}
            </>
          ) : savedPostDetails?.[`${socialMedia}_text`] && socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST ? (
            <>
              {!selectedMediaList.length ? <SocialMediaAlertMessage message={CREATOR_TEXT_EDITOR_ALERT_MSG.PINTEREST} /> : null}
              {hashTagsLimit.length > ADD_POST_CONTENT_MAX_HASHTAGS_LENGTH.PINTEREST ? <LimitExceedAlertMessage socialMedia={socialMedia} isHashTagLimit /> : null}
              {savedPostDetails?.[`${socialMedia}_text`].length > ADD_POST_CONTENT_MAX_TEXT_LENGTH.PINTEREST ? <LimitExceedAlertMessage socialMedia={socialMedia} /> : null}
            </>
          ) : savedPostDetails &&
            savedPostDetails[`${socialMedia}_text`] &&
            IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM.includes(socialMedia) &&
            savedPostDetails[`${socialMedia}_text`].length > ADD_POST_CONTENT_MAX_TEXT_LENGTH[socialMedia.toUpperCase()] ? (
            <LimitExceedAlertMessage socialMedia={socialMedia} />
          ) : isPhoneNumber ? (
            <SocialMediaAlertMessage message={CREATOR_TEXT_EDITOR_ALERT_MSG.GOOGLE} />
          ) : null /* [COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE].includes(socialMedia) && selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO ? null : [
            COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK
          ].includes(socialMedia) && selectedMediaList[0]?.media_type !== MG_MEDIA_TYPE.VIDEO ? null :  */
        }
        <CreatorTextEditor
          className="at-textarea"
          emojiClassName="roundedbtn ap-emoji-btn"
          emojiKey="location-creator"
          isShowAiButton={isRallioai}
          currentSocialMediaTab={savedPostDetails?.current_tab || ''}
          disableEmoji={
            IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM.includes(socialMedia) && savedPostDetails?.[`${socialMedia}_text`]?.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH[socialMedia.toUpperCase()] - 3
              ? true
              : false
          }
          isAiButtonDisable={isAiContentOpen}
          disableEditor={savedPostDetails?.locked || isStoriesAdded || false}
          editorState={textEditorState[socialMedia]}
          onEditorClick={() => {
            if (isPostEditable) {
              handleEditConfirmation();
            }
          }}
          isCoachMark={[SIDE_NAV_NAME.CONTENT_CAMPAIGNS, SIDE_NAV_NAME.CONTENT_CALENDAR].includes(subNavPageName) ? false : convertEditorStateToString(textEditorState[socialMedia]) ? false : true}
          handleAiClick={handleAiClick}
          isEmojiListOpen={isEmojiOpen}
          onEmojiClick={() => {
            if (isPostEditable) {
              handleEditConfirmation();
            } else {
              dispatch(addPostIsEmojiOpen(!isEmojiOpen));
            }
          }}
          onEditorChange={(currentValue) => handleUpdateSocialMediaText(socialMedia, currentValue)}
          isMentionEnabled={socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && isFBPageTaggingEnabled}
          suggestionList={facebookTags}
          onAddSuggestionTag={(suggestionData) => {
            dispatch(addPostGetSelectedFBTagList([...selectedFBTagList, suggestionData]));
          }}
          onSuggestionSearchChange={handleTagSearch}
          renderExtra={() => (
            <div>
              <div className={`${isStoriesAdded ? 'd-none' : ''}`}>
                <RenderAddContentList
                  socialMedia={socialMedia}
                  addContentList={addContentList}
                  savedPostDetails={savedPostDetails}
                  editableSocialMedia={editableSocialMedia}
                  setEditableSocialMedia={setEditableSocialMedia}
                  textEditorState={textEditorState}
                  setEditorState={setEditorState}
                  dispatch={dispatch}
                />
              </div>

              <CreatorTextCount socialMedia={socialMedia} currentTextCount={(savedPostDetails && savedPostDetails[`${socialMedia}_text`]?.length) || 0} />
            </div>
          )}
        />
        <CreatorInstagramPhotos
          socialMedia={socialMedia}
          isActionEnabled={!savedPostDetails?.locked}
          isOpacityEnabled={Boolean(savedPostDetails && !savedPostDetails[`use_${socialMedia}`])}
          // disabled={editData?.isCorporatePost}
          handleAddImages={() => setModalName(AP_ADD_TO_POST_TAB_NAMES.PHOTOS)}
          currentTab={savedPostDetails?.current_tab || ''}
        />
        <LeftCreatorMediaList
          socialMedia={socialMedia}
          setModalName={setModalName}
          selectedMediaList={selectedMediaList}
          savedPostDetails={savedPostDetails}
          editData={editData}
          editConfirmObj={editConfirmObj}
          isPostEditable={isPostEditable}
          dispatch={dispatch}
        />
        {!selectedMediaList.length && (isLinkFetching || linkData?.url) && (
          <div
            className={`creator-addcoupon-active-wrp${savedPostDetails && !savedPostDetails[`use_${socialMedia}`] ? ` button-opacity` : ``}`}
            onClick={() => {
              if (isPostEditable) {
                handleEditConfirmation();
              }
            }}
          >
            {isLinkFetching ? (
              <Loading isSmall />
            ) : (
              <CouponOrLinkPreview
                type={AP_PREVIEW_TYPES.LINK}
                // index={savedPostDetails?.link_index || 0}
                // imagesList={linkData?.link_preview_image_url_choices || []}
                previewTitle={linkData?.link_preview_title || ''}
                linkUrl={linkData?.url || ''}
                thumbnailShow={savedPostDetails?.is_thumbnail_show || false}
                description={linkData?.link_preview_description || ''}
                // handleUpdateIndex={(indexValue) => dispatch(addPostUpdateSavedPostDetails(savedPostDetails ? { ...savedPostDetails, link_index: indexValue } : null))}
                handleUpdateThumbnail={() => dispatch(addPostUpdateSavedPostDetails(savedPostDetails ? { ...savedPostDetails, is_thumbnail_show: !savedPostDetails?.is_thumbnail_show } : null))}
                handleDeletePreview={() => handleDeletePreview()}
                activeImgUrl={linkData?.link_preview_image_url || ''}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="icon-tabs">
        <ul className="nav nav-posts nav nav-tabs" role="tablist">
          {socialMediaList.map((mediaDatum) => {
            const socialMediaLabel = CREATOR_SOCIAL_MEDIAS.includes(mediaDatum) ? COMMON_SOCIAL_MEDIA_NAME[mediaDatum.toUpperCase()] : COMMON_SOCIAL_MEDIA_NAME.GOOGLE;
            return (
              <li key={mediaDatum} className="nav-item">
                <button
                  type="button"
                  onClick={() => handleSocialMediaSelect(mediaDatum)}
                  data-rr-ui-event-key={mediaDatum}
                  className={`${savedPostDetails && savedPostDetails[`use_${mediaDatum}`] ? 'content-added' : 'selected-media'} nav-link ${
                    savedPostDetails?.current_tab === mediaDatum ? 'active' : ''
                  } ${savedPostDetails?.locked ? 'pointer-events-none' : ''}`}
                >
                  {socialMediaLabel}
                </button>
              </li>
            );
          })}
        </ul>
        <div className="tab-content">
          {renderSocialMediaTabDetails(
            savedPostDetails?.current_tab || COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK,
            savedPostDetails?.current_tab && CREATOR_SOCIAL_MEDIAS.includes(savedPostDetails.current_tab)
              ? savedPostDetails.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
                ? COMMON_SOCIAL_MEDIA_NAME.X
                : COMMON_SOCIAL_MEDIA_NAME[savedPostDetails.current_tab.toUpperCase()]
              : COMMON_SOCIAL_MEDIA_NAME.GOOGLE
          )}
          <div className="add__post--main">
            {/* <span className="add__post__title">Add to Your Post</span> */}
            <div className="post__icon--wrp loc__icon--wrp">
              <AddToYourPostSection />
            </div>
          </div>
          <div className="ap-emoji cp-emoji creator-emoji">
            {isEmojiOpen && (
              <Picker
                previewPosition="none"
                onEmojiSelect={(emo: BaseEmoji) => {
                  if (savedPostDetails?.current_tab) {
                    let contentState = textEditorState[savedPostDetails?.current_tab].getCurrentContent();
                    const currentSelection = textEditorState[savedPostDetails?.current_tab].getSelection();

                    contentState = contentState.createEntity('EMOJI', 'IMMUTABLE', `${emo.native}\u200A`);
                    const emoEntityKey = contentState.getLastCreatedEntityKey();

                    const newContentState = Modifier.insertText(contentState, currentSelection, `${emo.native}\u200A`, undefined, emoEntityKey);
                    let newEditorState = EditorState.push(textEditorState[savedPostDetails?.current_tab], newContentState, 'insert-characters');

                    newEditorState = EditorState.moveFocusToEnd(newEditorState);
                    newEditorState = EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter());

                    handleUpdateSocialMediaText(savedPostDetails?.current_tab, newEditorState);
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
