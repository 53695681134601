import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { IStore } from 'types';
import { modifyLocationsHubListResponse, onChangeList /* operationListsResetModifiedObj, */ /* subscriptionsLocationToListRequest */ } from 'actions';
import { KeywordsCard } from 'analytics/containers/ReviewAnalytics/SubPages';
import { IKeywordCardData } from 'analytics/types';
import { ImageValidation } from 'widgets/Media';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { CONNECTION_TABS, RIPPLE_COLOR } from 'utils/constants';
import { compareTwoObjects, getAccValidSocialMediaForCreator } from 'utils/helpers';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';

export const TMListManageSocialMediaModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership, currentRoute, optionalParams } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor();

  const contentFeedModifiedObj = useSelector((state: IStore) => state.operationsList.modifiedObj);
  const locationsGroupList = useSelector((state: IStore) => state.operationsList.locationsGroupList);
  const modifyLocationGroups = useSelector((state: IStore) => state.operationsList.modifiedLocationGroupList);
  const accountProfiles = useSelector((state: IStore) => state.accounts.accountDetails?.account_profiles || []);
  const gmbFeatureEnabled = useSelector((state: IStore) => state.accounts.accountDetails?.account.gmb_feature_enabled || false);

  const isFromAccountWizard = optionalParams[0] === 'account_wizard' && optionalParams[2] === CONNECTION_TABS.FEED;
  const activePlatforms = getAccValidSocialMediaForCreator(accountProfiles, gmbFeatureEnabled);

  const updatedSocialMediaList: IKeywordCardData[] = activePlatforms.map((platform) => ({
    name: platform
  }));
  const selectedFeedList = locationsGroupList.find((it) => it.id === (isFromAccountWizard ? +optionalParams[3] : +optionalParams[0]));

  const getSelectedFeedListSocialMedia = () => {
    return {
      use_facebook: selectedFeedList?.use_facebook ?? false,
      use_instagram: selectedFeedList?.use_instagram ?? false,
      use_twitter: selectedFeedList?.use_twitter ?? false,
      use_linkedin: selectedFeedList?.use_linkedin ?? false,
      use_google: selectedFeedList?.use_google ?? false,
      use_tiktok: selectedFeedList?.use_tiktok ?? false,
      use_pinterest: selectedFeedList?.use_pinterest ?? false
    };
  };

  const getCheckedKeys = () => {
    if (contentFeedModifiedObj?.platforms) {
      return Object.keys(contentFeedModifiedObj?.platforms).reduce((acc: string[], curr) => {
        if (contentFeedModifiedObj?.platforms[curr]) {
          acc.push(curr.replace('use_', ''));
        }
        return acc;
      }, []);
    }
    return [];
  };

  const handleModalClose = () => {
    if (optionalParams?.[0] === 'account_wizard') {
      navigate({
        pathname: `/${userOwnership}/${id}/${currentRoute}/account_wizard/${id}/${CONNECTION_TABS.FEED}`,
        search: `?${new URLSearchParams(queryParams).toString()}`
      });
    } else {
      navigate({
        pathname: `/${userOwnership}/${id}/content/feed`
      });
    }
  };

  const handleUpdateSocialMediaChanges = () => {
    // if (isFromAccountWizard) {
    //   dispatch(
    //     subscriptionsLocationToListRequest({ account_id: id, account_list_id: selectedFeedList?.id, account_included: true, isFromAccountWizard, platforms: contentFeedModifiedObj?.platforms })
    //   );
    // } else {
    if (selectedFeedList) {
      const modifyLocationGroupsCopy = modifyLocationGroups?.length ? [...modifyLocationGroups] : [];
      const selectedFeedListIndex = modifyLocationGroups.findIndex((it) => it.id === selectedFeedList?.id);
      if (!modifyLocationGroups?.length || selectedFeedListIndex === -1) {
        modifyLocationGroupsCopy.push({ ...selectedFeedList, platforms: contentFeedModifiedObj?.platforms });
      } else {
        modifyLocationGroupsCopy[selectedFeedListIndex] = {
          ...modifyLocationGroupsCopy[selectedFeedListIndex],
          platforms: contentFeedModifiedObj?.platforms
        };
      }
      dispatch(modifyLocationsHubListResponse(modifyLocationGroupsCopy));
      // }
    }

    handleModalClose();
    // dispatch(operationListsResetModifiedObj());
  };

  return (
    <Modal show className="nlfWraps cfp-m-atp-wrap" onHide={handleModalClose}>
      <div className="mod__close--icon">
        <ImageValidation isImgValid defaultImg="mpcIcon" customClassname="close-icon close mod__close--img wh-30" customName={'close'} onClick={handleModalClose} />
      </div>
      <ModalBody>
        <div className="tml-main tml-createList mbl-head--margin tmLf-nl bsFeedList nlfGroup">
          <h2>Add Target Platform</h2>
          <div className="nlfContent">
            <div className="nlfSteps">
              <div className="stfcWrap">
                <div className="create-as d-flex">
                  <KeywordsCard
                    title=""
                    subtitle={``}
                    data={updatedSocialMediaList}
                    onChange={(value) => {
                      dispatch(
                        onChangeList({
                          key: 'platforms',
                          value: { ...contentFeedModifiedObj?.platforms, [`use_${value}`]: !contentFeedModifiedObj?.platforms[`use_${value}`] }
                        })
                      );
                    }}
                    checkable
                    checkedKeys={getCheckedKeys() || []}
                    hasMore={false}
                    isFetching={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right-button-section nlfActions">
          {/* <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
            <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={handleModalClose}>
              Cancel
            </button>
          </CustomRippleButton> */}
          <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
            <button
              className={`ac-btn ac-primary ac-block kc-save ${compareTwoObjects(getSelectedFeedListSocialMedia(), contentFeedModifiedObj?.platforms) ? 'pointer-events-none' : ''}`}
              type="submit"
              onClick={handleUpdateSocialMediaChanges}
            >
              Submit
            </button>
          </CustomRippleButton>
        </div>
      </ModalBody>
    </Modal>
  );
};
