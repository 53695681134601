import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import moment from 'moment-timezone';

import { calendarApiTypes, calendarReducerTypes, IStore } from 'types';
import { CalenderFilterModal } from 'containers/Content/Calendar/SubPages/CalendarFilterModal';
import { PLATFORM_FILTER, handleFilterChange } from 'analytics/utils';
import {
  updateScheduledPostRequest,
  deleteScheduledMultiPostRequest,
  calendarEditView,
  addPostUpdateSavedPostRequest,
  postsResetAll,
  addPostResetAll,
  addPostFranResetAll,
  addPostActionsResetAll,
  updateFBBoostConfigData,
  addPostGetActionTypeForPost,
  addPostGetEditSavedPostObj,
  addPostUpdateSavedPostDetails,
  addPostSetCustomTextEditorData,
  addPostGetSelectedFBTagList,
  addPostSetTags,
  addPostSetSelectedMediaList,
  addPostSetLinkData,
  getActivePlatormInScheduledPost,
  addPostSetInstaPhotoPreviewObj,
  addPostSetCampaignData,
  commonSetContentModifiedStatus,
  calendarDeleteModalOption,
  accountListSchedulesBlockListRequest,
  accountDeleteScheduleRequest,
  calendarAutomatedScheduledPostDeleteModalOption
} from 'actions';
import {
  USER_OWNERSHIP,
  AP_ACTION_TYPES,
  CALENDAR_FILTER_INITIAL_VALUE,
  CalendarValidQueryParams,
  CALENDAR_PAGE_VIEW_TYPES,
  CALENDER_STATS,
  SCHEDULE_PARAM_TYPE,
  CreatorInstaPhotoType,
  CommonValidQueryParams,
  COMMON_VALID_QUERY_PARAMS_OBJ,
  CALENDAR_CS_CONTENT_TYPE_OPTIONS
} from 'utils/constants';
import {
  calendarUTCtoLocalHandler,
  endDateLessThanStartDate,
  utcTimeStampConverter,
  getApUpdatePostActionReqPayload,
  getCalendarSchReqPayload,
  getCurrentDate,
  getCorporatePostDetail,
  getEditOrSchedulePost,
  getUpdatedTagsList,
  getCreatorLinkData,
  getAddMediaListForCreator,
  getPostContentTextField,
  canEditScheduledPost,
  canApproveScheduledPost,
  getInitialLocationScheduleType
} from 'utils/helpers';
import { useAccountSwitcherData, useActivePlatform, useAvailablePlatforms, useParamsDeconstructor, useParamsValidator } from 'utils/hooks';
import { CalendarDeleteAlertModal } from 'containers/Content/Calendar/SubPages/CalendarDeleteAlertModal';
import { CalendarDatePicker } from 'containers/Content/Calendar/SubPages/CalendarDatePickerModal';
import { ICalendarDataType, IDataObjType } from 'types/calendar/calendar-container';
import { BrandSchedulePostModal, CommonFilter } from 'components';
import { CalendarViewEventFilter, CalendarViewListFilter, CalendarViewPostFilter, CalendarAutomatedScheduledPostDeleteModal } from 'containers/Content/Calendar/SubPages';

export const CalendarFilter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership, optionalParams, currentRoute } = useAccountSwitcherData();
  const availablePlatform = useAvailablePlatforms(PLATFORM_FILTER, userOwnership, null, false).filter((it) => it.value !== 'yelp');
  const { queryParams, filter } = useParamsDeconstructor({ ...CALENDAR_FILTER_INITIAL_VALUE });
  const activePlatforms = useActivePlatform();

  const editId = useSelector((state: IStore) => state.calendar.editableData.editId);
  const scheduleMultiPostList = useSelector((state: IStore) => state.calendar.scheduleMultiPostList); // hub scheduleMultiPostList
  const scheduledPostList = useSelector((state: IStore) => state.calendar.scheduledPostList); // location scheduledPostList
  const isFetching = useSelector((state: IStore) => state.calendar.isFetching); // location scheduledPostList
  const locationActualData = useSelector((state: IStore) => state.calendar.locationActualData);
  const hubActualData = useSelector((state: IStore) => state.calendar.hubActualData);
  const calendarEventItemList = useSelector((state: IStore) => state.calendar.calendarItemList);
  const calendarDateRange = useSelector((state: IStore) => state.calendar.calendarDateRange);
  const editData = useSelector((state: IStore) => state.calendar.editableData.editData);
  const { selectedTags, savedPostDetails, instaPhotoPreviewObj, editSavedPost } = useSelector((state: IStore) => state.addPostAccount);
  const { actionType: instaType } = instaPhotoPreviewObj;
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const linkObj = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const overriddenCorporatePostsLocked = useSelector((state: IStore) => state.accounts.permissions.franchiseePermissions?.overridden_corporate_posts_locked || false);
  const overriddenCustomPostsLocked = useSelector((state: IStore) => state.accounts.permissions.franchiseePermissions?.overridden_custom_posts_locked || false);
  const overriddenCorporatePostsAutoApproved = useSelector((state: IStore) => state.accounts.permissions.franchiseePermissions?.overridden_corporate_posts_auto_approved || false);
  const accountTimeZone = useSelector((state: IStore) => state.accounts.accountDetails?.account?.tzinfo_name || '');
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);
  const isFranchisee = useSelector((state: IStore) => state.accounts.accountDetails?.account_profiles[0]?.is_franchisee || false);
  const contentModified = useSelector((state: IStore) => state.common.contentModified);
  const locationsCount = useSelector((state: IStore) => state.accountSwitcher.childCount);
  const corporateAccountId = useSelector((state: IStore) => state.franchisors.franchisorDetails?.corporate_account_id || null);
  const isAiPost = useSelector((state: IStore) => state.aiContentCreator.isAiPost);
  const locationUpdateStates = useSelector((state: IStore) => state.calendar.locationUpdates);
  const deleteAutomatedScheduledPostOptions = useSelector((state: IStore) => state.calendar.deleteAutomatedScheduledPostOptions);
  const reducerTextEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.textEditorState);

  const pageView = queryParams?.view || 'calendar';
  const isHubUser = USER_OWNERSHIP.FRANCHISOR === userOwnership;
  const calendarFilter = useMemo(() => {
    return {
      ...filter,
      platform: queryParams?.platform || 'all',
      view: pageView,
      content_type: queryParams?.content_type || 'all'
    };
  }, [queryParams, pageView, filter]);

  const detailPostId = optionalParams?.length > 0 ? (CALENDER_STATS.map((it) => it.value).includes(optionalParams[0]) ? optionalParams[1] : optionalParams[0]) : ''; // eslint-disable-line

  useParamsValidator(
    { ...CommonValidQueryParams, ...CalendarValidQueryParams },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      platform: availablePlatform.filter((it) => it?.value !== 'all').map((it) => it?.value || ''),
      view: [CALENDAR_PAGE_VIEW_TYPES.LIST],
      content_type: ['1']
    }
  );

  useEffect(() => {
    if (id && availablePlatform.length === 1 && !queryParams?.platform) {
      navigate({ search: `?${new URLSearchParams({ platform: availablePlatform[0]?.value || '' }).toString()}` }, { replace: true });
    }
  }, [id, navigate, availablePlatform, queryParams?.platform]);

  const filterData: ICalendarDataType[] = isHubUser ? scheduleMultiPostList : scheduledPostList.filter((it) => !it?.hidden || !it?.draft);
  const actualResponse = isHubUser ? hubActualData : locationActualData.filter((it) => !it?.hidden || !it?.draft);
  const deleteModal = useSelector((state: IStore) => state.calendar.deleteModal);

  const [isScheduleModalOpen, setScheduleModal] = useState(false);

  useEffect(() => {
    if (!isFetching && calendarFilter?.platform) {
      dispatch(getActivePlatormInScheduledPost({ activePlatforms, selectedPlatform: calendarFilter?.platform }));
    }
  }, [isFetching, calendarFilter?.platform, dispatch]); // eslint-disable-line

  /** Handle changes in Post content */
  const handleOnChangePostContent = useCallback(
    (paramName: string, val: string | boolean | null, tempCurrentPostView: ICalendarDataType, onSuccess?: any) => {
      const actualData: calendarApiTypes.IGetScheduledPostResponse | calendarApiTypes.IGetScheduledMultiPostResponse | undefined = [...actualResponse]?.find(
        (it) => it?.id === tempCurrentPostView?.id
      );
      const dispatchData = { ...actualData, [`${paramName}`]: val, ...(paramName === SCHEDULE_PARAM_TYPE.HIDDEN && val && { draft: true }) };
      dispatch(
        updateScheduledPostRequest({
          activePlatforms,
          payload: dispatchData,
          isCalendarView: true,
          dateRange: calendarDateRange?.startDate && calendarDateRange?.endDate ? [utcTimeStampConverter(calendarDateRange?.startDate), utcTimeStampConverter(calendarDateRange?.endDate)] : [],
          onSuccess,
          ...(deleteAutomatedScheduledPostOptions.post === 2 && { isDeletePost: true }),
          ...(deleteAutomatedScheduledPostOptions.timeSlot === 3 && { isRemoveAllScheduledPostOnThisTimeSlot: true })
        })
      );
    },
    [activePlatforms, actualResponse, calendarDateRange?.endDate, calendarDateRange?.startDate, deleteAutomatedScheduledPostOptions.post, deleteAutomatedScheduledPostOptions.timeSlot, dispatch]
  );

  const hubPostDeleteHandler = (datum: ICalendarDataType) => {
    // if (deleteModal.isConfirmDelete) {
    if (calenderViewData?.id) {
      dispatch(calendarDeleteModalOption({ isOpenDeleteModal: false, isConfirmDelete: false }));
      dispatch(deleteScheduledMultiPostRequest({ postId: datum?.id || calenderViewData.id, contentId: datum?.contentId || calenderViewData.contentId }));
    }
    // }
  };

  // LOCATION USER EDIT-UPDATE HANDLER
  const handleUpdateSavedPostData = (actionType: string, eventId?: number) => {
    let reqPayload;
    if (id && userOwnership && savedPostDetails && editSavedPost?.id) {
      reqPayload = getApUpdatePostActionReqPayload(
        editSavedPost,
        savedPostDetails,
        selectedFBTagList,
        selectedMediaList,
        selectedTags,
        linkObj.linkData,
        actionType,
        instaType,
        reducerTextEditorState?.facebook,
        [],
        false,
        editSavedPost?.ai_post || isAiPost
      );
      if (editSavedPost?.id === editData?.savedPostId) dispatch(addPostUpdateSavedPostRequest({ actionType, savedPostId: editData?.savedPostId, reqBody: reqPayload }));
    } else if (calenderViewData && id) {
      reqPayload = getCalendarSchReqPayload(calenderViewData, id);
    }
    const actualData = {
      ...reqPayload,
      account_id: id,
      id: eventId || calenderViewData?.id,
      scheduled_for: savedPostDetails?.schedule_date_time.utcFormat
    };
    dispatch(
      updateScheduledPostRequest({
        activePlatforms,
        payload: actualData,
        isCalendarView: true,
        dateRange:
          pageView === CALENDAR_PAGE_VIEW_TYPES.CALENDAR && calendarDateRange?.startDate && calendarDateRange?.endDate
            ? [utcTimeStampConverter(calendarDateRange?.startDate), utcTimeStampConverter(calendarDateRange?.endDate)]
            : [],
        refetch: true
      })
    );
    dispatch(calendarEditView({ isEditEnabled: false, editId: 0, editData: null }));
    editModalCloseHandler();
    resetDispatchHandler();
  };

  const editModalCloseHandler = () => {
    dispatch(calendarEditView({ isEditEnabled: false, editId: 0, editData: null }));
  };

  const resetDispatchHandler = () => {
    if (contentModified) dispatch(commonSetContentModifiedStatus(false));
    dispatch(postsResetAll());
    dispatch(addPostResetAll());
    dispatch(addPostFranResetAll());
    dispatch(addPostActionsResetAll());
    dispatch(updateFBBoostConfigData(null));
  };

  // LOCATION AND HUB USER EDIT HANDLER
  const editClickHandler = (data: null | ICalendarDataType) => {
    if (data) dispatch(calendarEditView({ isEditEnabled: false, editId: data.id, editData: data }));
  };

  // LOCATION DISPATCH EVENT IF THE POST IS CREATED BY THEIR HUB
  const handleEditCorporatePost = useCallback(
    (data: null | ICalendarDataType) => {
      if (data?.id) {
        dispatch(calendarEditView({ isEditEnabled: false, editId: data.id, editData: data }));
        const selectedLocData = locationActualData.find((it) => it.id === data.id);
        if (selectedLocData?.id) {
          dispatch(addPostGetActionTypeForPost(AP_ACTION_TYPES.EDIT));
          dispatch(addPostGetEditSavedPostObj(getCorporatePostDetail(activePlatforms, selectedLocData)));
          dispatch(
            addPostUpdateSavedPostDetails(
              getEditOrSchedulePost({
                activePlatforms,
                postDetails: { ...selectedLocData, created_at: data?.scheduledTime || '' },
                accountTzInfoName: accountTimeZone,
                postPlatform: null,
                isCalendarView: true
              })
            )
          );
          dispatch(addPostSetCustomTextEditorData(getPostContentTextField(activePlatforms, selectedLocData)));
          dispatch(addPostGetSelectedFBTagList(selectedLocData?.page_tag_details?.facebook || []));
          dispatch(addPostSetTags(getUpdatedTagsList('')));
          dispatch(addPostSetSelectedMediaList(getAddMediaListForCreator(selectedLocData, accountDetails?.account?.id || 0)));
          dispatch(addPostSetLinkData(selectedLocData?.link_url ? getCreatorLinkData(selectedLocData) : null));
          if (selectedLocData?.use_instagram && selectedLocData?.photo_urls?.length) {
            dispatch(
              addPostSetInstaPhotoPreviewObj({
                actionType: selectedLocData?.pad_instagram_photos ? CreatorInstaPhotoType.PAD : CreatorInstaPhotoType.CROP,
                photoUrl: { originalUrls: [], previewUrls: [] }
              })
            );
          }
          if (selectedLocData?.campaigns?.length) {
            dispatch(addPostSetCampaignData(selectedLocData?.campaigns));
          }
          dispatch(calendarEditView({ isEditEnabled: true, editId: 0, editData: data }));
        }
      }
    },
    [accountDetails?.account?.id, accountTimeZone, activePlatforms, dispatch, locationActualData]
  );

  let data: ICalendarDataType[] =
    isHubUser && pageView === CALENDAR_PAGE_VIEW_TYPES.LIST
      ? filterData
      : optionalParams[0] === 'scheduled'
      ? filterData.filter((it) => !it.draft)
      : optionalParams[0] === 'awaiting_approval'
      ? filterData.filter((it) => it.draft)
      : filterData;

  const dataArr: ICalendarDataType[] = [];
  if (calendarFilter?.platform !== 'all') {
    data.filter((it) => dataArr.push({ ...it, dataObj: it.dataObj.filter((it: IDataObjType) => it.label === calendarFilter?.platform) }));
    data = dataArr.filter((it) => it.dataObj.length);
  }

  const calenderViewData: null | ICalendarDataType = data.length && +detailPostId ? data.find((it) => it?.id === +detailPostId) || null : data.length ? data[0] : null;
  const isEventListData = +detailPostId ? calendarEventItemList?.some((it) => it?.id === +detailPostId) : false;
  const calendarEventData =
    optionalParams[0] === 'events'
      ? +detailPostId
        ? calendarEventItemList?.find((it) => it?.id === +detailPostId)
        : calendarEventItemList?.length
        ? calendarEventItemList[0]
        : null
      : calenderViewData;

  const canEditAccountPost =
    !isHubUser && calenderViewData?.id
      ? canEditScheduledPost(
          calenderViewData.isLocked,
          calenderViewData.isCorporatePost,
          isFranchisee,
          overriddenCorporatePostsLocked,
          overriddenCustomPostsLocked,
          calenderViewData?.abilities?.can_edit_content
        )
      : false;
  const canApprove =
    !isHubUser && calenderViewData?.id
      ? canApproveScheduledPost(calenderViewData.draft, calenderViewData.isCorporatePost, isFranchisee, overriddenCorporatePostsAutoApproved, overriddenCustomPostsLocked)
      : false;

  const buttonEnabled = () => {
    if (isHubUser) {
      const postDate = new Date(calendarUTCtoLocalHandler(calenderViewData?.scheduledTime || '', ''));
      const todayDate = new Date(calendarUTCtoLocalHandler(getCurrentDate(), ''));
      const futurePostsCount = calenderViewData?.future_scheduled_posts_count;
      const pastPostsCount = calenderViewData?.past_scheduled_posts_count;
      const hasPostsCounts = futurePostsCount !== undefined && pastPostsCount !== undefined;

      // In the case of ScheduledMultipost records, the hub user can remove the
      // post if either:
      //
      // - there are yet-unpublished posts in the multipost
      // - there are no posts at all in the multipost
      if (hasPostsCounts && futurePostsCount !== null && futurePostsCount > 0) {
        return true;
      } else if (hasPostsCounts && futurePostsCount === 0 && pastPostsCount === 0) {
        return true;
      } else if (hasPostsCounts) {
        return false;
      } else {
        // If somehow the post has no posts counts, check the dates instead.
        // (I think this path is impossible, but I'm not sure.)
        return endDateLessThanStartDate(todayDate, postDate);
      }
    } else if (!isHubUser && calenderViewData?.scheduledTime) {
      return moment(calenderViewData.scheduledTime).isAfter(moment());
    } else return true;
  };

  const isButtonsEnabled = buttonEnabled();

  const handleChange = (data: Partial<calendarReducerTypes.ICalendarFiltersVal>, isClear = false) => {
    const queryParamsObj = handleFilterChange(data, isClear, id, queryParams);
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };
  const confirmDeleteAutomatedScheduledPost = (onSuccess?: any) => {
    if (locationUpdateStates.data) {
      handleOnChangePostContent(locationUpdateStates.type, locationUpdateStates.bool, locationUpdateStates.data, onSuccess);
    }
  };

  const confirmDeleteAutomatedScheduledPostTimeSlot = async () => {
    if (deleteAutomatedScheduledPostOptions.timeSlot === 1) {
      confirmDeleteAutomatedScheduledPost();
    } else if (deleteAutomatedScheduledPostOptions.timeSlot === 2) {
      dispatch(
        accountListSchedulesBlockListRequest({
          id: calendarEventData?.account_list_schedule_details?.id,
          request: { scheduled_for: calendarEventData?.account_list_schedule_details?.original_scheduled_for }
        })
      );
      confirmDeleteAutomatedScheduledPost();
    } else {
      confirmDeleteAutomatedScheduledPost(() => dispatch(accountDeleteScheduleRequest({ id, userOwnership, list_id: calendarEventData?.account_list_schedule_details?.id })));
    }
    navigate({
      pathname: `/${userOwnership}/${id.toString()}/${currentRoute}${CALENDER_STATS.map((it) => it.value).includes(optionalParams[0]) ? `/${optionalParams[0]}` : ''}`
    });
    resetAutomatedScheduledPostDeleteModalOption();
    dispatch(calendarDeleteModalOption({ isOpenDeleteModal: false, isConfirmDelete: false }));
  };

  const resetAutomatedScheduledPostDeleteModalOption = () => {
    dispatch(calendarAutomatedScheduledPostDeleteModalOption({ post: 1, timeSlot: 1 }));
  };

  const renderCalendarData = useCallback(() => {
    return (
      <>
        {calendarFilter?.view === CALENDAR_PAGE_VIEW_TYPES.LIST && !isFetching ? (
          <CalendarViewListFilter calendarEventItemList={calendarEventItemList} data={data} calendarFilter={calendarFilter} />
        ) : !isFetching && calendarEventData?.id && optionalParams[0] === 'events' ? (
          <CalendarViewEventFilter calendarEventData={calendarEventData} />
        ) : !isFetching && calenderViewData?.id && optionalParams[0] !== 'events' ? (
          <CalendarViewPostFilter
            data={data}
            isButtonsEnabled={isButtonsEnabled}
            handleEditCorporatePost={handleEditCorporatePost}
            canEditAccountPost={canEditAccountPost}
            detailPostId={detailPostId}
            availablePlatform={availablePlatform}
            handleOnChangePostContent={handleOnChangePostContent}
            canApprove={canApprove}
            setScheduleModal={setScheduleModal}
          />
        ) : null}
      </>
    );
  }, [
    availablePlatform,
    calendarEventData,
    calendarEventItemList,
    calendarFilter,
    calenderViewData?.id,
    canApprove,
    canEditAccountPost,
    data,
    detailPostId,
    handleEditCorporatePost,
    handleOnChangePostContent,
    isButtonsEnabled,
    isFetching,
    optionalParams
  ]);

  return (
    <>
      <CommonFilter
        filter={{ ...calendarFilter }}
        handleChange={handleChange}
        isDateRangeFilterEnable={false}
        analyticsPlatformFilter={availablePlatform}
        checkBoxFilter={[
          {
            title: 'Calendar View',
            filter: CALENDAR_CS_CONTENT_TYPE_OPTIONS.map((option) => {
              return {
                value: option.identifier,
                label: option.label
              };
            }),
            value: 'content_type'
          }
        ]}
        sectionVariant={'calendar-filter__main'}
        renderPrefixData={() => (pageView === CALENDAR_PAGE_VIEW_TYPES.CALENDAR ? renderCalendarData() : <></>)}
        renderSuffixData={() => (pageView === CALENDAR_PAGE_VIEW_TYPES.LIST ? renderCalendarData() : <></>)}
      />
      {+detailPostId ? (
        <CalenderFilterModal
          editId={editId}
          calenderViewData={calendarEventData}
          displayType={CALENDER_STATS.map((it) => it.value).includes(optionalParams[0]) ? optionalParams[0] : 'all'}
          editClickHandler={(data) => (data.isCorporatePost ? handleEditCorporatePost(data) : editClickHandler(data))}
          setScheduleModal={setScheduleModal}
          handleOnChangePostContent={handleOnChangePostContent}
          isEventListData={isEventListData}
          isButtonsEnabled={isButtonsEnabled}
          canEditAccountPost={canEditAccountPost}
          canApprove={canApprove}
        />
      ) : null}

      {deleteModal.isOpenDeleteModal ? (
        locationUpdateStates?.data?.account_list_schedule_details?.id ? (
          <CalendarAutomatedScheduledPostDeleteModal
            isModalShow
            handleModalCancel={() => {
              dispatch(calendarDeleteModalOption({ isOpenDeleteModal: false, isConfirmDelete: false }));
              resetAutomatedScheduledPostDeleteModalOption();
            }}
            handleModalClose={confirmDeleteAutomatedScheduledPostTimeSlot}
            postDetail={locationUpdateStates?.data}
          />
        ) : (
          <CalendarDeleteAlertModal isLocationUser={!isHubUser} states={locationUpdateStates} dispatchFunction={isHubUser ? hubPostDeleteHandler : handleOnChangePostContent} />
        )
      ) : null}

      {isScheduleModalOpen && !isHubUser ? (
        <CalendarDatePicker modalOpenFunction={setScheduleModal} dispatchFunction={handleUpdateSavedPostData} states={calenderViewData} endDate={calendarEventData?.end_date} />
      ) : null}
      {isScheduleModalOpen && isHubUser ? (
        <BrandSchedulePostModal
          isModalShow
          handleModalClose={() => setScheduleModal(false)}
          scheduleType={getInitialLocationScheduleType({
            totalLocationCount: locationsCount,
            corporateAccountId,
            includedAccounts: calenderViewData?.includedAccounts || [],
            franOrAccountListIds: calenderViewData?.franchisorIds || calenderViewData?.accountListIds || []
          })}
          selectedObj={calenderViewData}
          isEditEnabled={isButtonsEnabled}
        />
      ) : null}
    </>
  );
};
